import * as React from 'react';

export const IconUsersGroup = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8877 8.37821C16.4735 7.96392 15.8018 7.96392 15.3875 8.37821C14.9732 8.79249 14.9732 9.46417 15.3875 9.87845C15.8018 10.2927 16.4735 10.2927 16.8877 9.87845C17.302 9.46417 17.302 8.79249 16.8877 8.37821ZM17.7709 7.49361C16.8684 6.59189 15.4058 6.59212 14.5036 7.49432C13.6012 8.39676 13.6012 9.85989 14.5036 10.7623C15.4061 11.6648 16.8692 11.6648 17.7716 10.7623C18.6741 9.85989 18.6741 8.39676 17.7716 7.49432C17.7714 7.49408 17.7712 7.49385 17.7709 7.49361Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3962 5.36891C10.6265 4.59925 9.37863 4.59925 8.60898 5.3689C7.83932 6.13857 7.83932 7.38643 8.60898 8.15609C9.37864 8.92575 10.6265 8.92574 11.3962 8.15609C12.1658 7.38643 12.1658 6.13856 11.3962 5.36891ZM12.2797 4.48471C11.0219 3.22721 8.9828 3.22731 7.72509 4.48502C6.46729 5.74284 6.46728 7.78216 7.72509 9.03997C8.98291 10.2978 11.0222 10.2978 12.28 9.03997C13.5379 7.78216 13.5375 5.74252 12.2797 4.48471C12.2796 4.4846 12.2798 4.48481 12.2797 4.48471Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61773 8.37821C4.20345 7.96392 3.53177 7.96392 3.11749 8.37821C2.70321 8.79249 2.70321 9.46417 3.11749 9.87845C3.53177 10.2927 4.20345 10.2927 4.61773 9.87845C5.03201 9.46417 5.03201 8.79249 4.61773 8.37821ZM5.5009 7.49361C4.5984 6.59189 3.1358 6.59212 2.2336 7.49432C1.33117 8.39676 1.33117 9.85989 2.2336 10.7623C3.13604 11.6648 4.59918 11.6648 5.50161 10.7623C6.40405 9.85989 6.40405 8.39676 5.50161 7.49432C5.50137 7.49408 5.50114 7.49385 5.5009 7.49361Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7934 12.8367C15.7934 12.4915 16.0733 12.2117 16.4184 12.2117H17.0859C18.5819 12.2117 19.7943 13.424 19.7943 14.92V15.8333C19.7943 16.1785 19.5144 16.4583 19.1693 16.4583C18.8241 16.4583 18.5443 16.1785 18.5443 15.8333V14.92C18.5443 14.1143 17.8916 13.4617 17.0859 13.4617H16.4184C16.0733 13.4617 15.7934 13.1818 15.7934 12.8367Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91927 13.4617C2.11362 13.4617 1.46094 14.1143 1.46094 14.92V15.8333C1.46094 16.1785 1.18112 16.4583 0.835937 16.4583C0.490759 16.4583 0.210938 16.1785 0.210938 15.8333V14.92C0.210938 13.424 1.42326 12.2117 2.91927 12.2117H3.58677C3.93195 12.2117 4.21177 12.4915 4.21177 12.8367C4.21177 13.1818 3.93195 13.4617 3.58677 13.4617H2.91927Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9276 14.4992C4.9276 12.5432 6.51326 10.9575 8.46927 10.9575H11.5351C13.4911 10.9575 15.0768 12.5432 15.0768 14.4992V15.8333C15.0768 16.1785 14.7969 16.4583 14.4518 16.4583C14.1066 16.4583 13.8268 16.1785 13.8268 15.8333V14.4992C13.8268 13.2335 12.8008 12.2075 11.5351 12.2075H8.46927C7.20361 12.2075 6.1776 13.2335 6.1776 14.4992V15.8333C6.1776 16.1785 5.89778 16.4583 5.5526 16.4583C5.20743 16.4583 4.9276 16.1785 4.9276 15.8333V14.4992Z"
        fill="currentColor"
      />
    </svg>
  );
};
