import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';
import { IconSpecificSuitcase } from 'components/Icon/SVGIcons/IconSpecificSuitcase';
import { IconNavigationPin } from 'components/Icon/SVGIcons/IconNavigationPin';
import { IconTimeTime2 } from 'components/Icon/SVGIcons/IconTimeTime2';
import { IconUsersGroup } from 'components/Icon/SVGIcons/IconUsersGroup';
import { IconSpecificMoney } from 'components/Icon/SVGIcons/IconSpecificMoney';
import { fromScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  gap: 12px;

  border: 1px solid ${colorsV2.gray20};
  border-radius: 8px;
  padding: 12px;
  ${fromScreen(458)} {
    padding: 24px;
  }
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: max-content 124px minmax(0, 1fr);
  gap: 8px;
`;

export const CareerDescription = ({
  isPartTime,
  amount,
  salary,
  address,
  formsOfWork
}) => {
  return (
    <Container>
      <Item>
        <IconSpecificSuitcase fontSize={20} />
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="black100"
        >
          Loại công việc:
        </ResponsiveTypography.Paragraph>
        <ResponsiveTypography.Paragraph
          variant="semi-bold/14-20"
          color="black100"
        >
          {isPartTime ? 'Bán thời gian' : 'Toàn thời gian'}
        </ResponsiveTypography.Paragraph>
      </Item>
      <Item>
        <IconTimeTime2 fontSize={20} />
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="black100"
        >
          Giờ làm việc:
        </ResponsiveTypography.Paragraph>
        <ResponsiveTypography.Paragraph
          variant="semi-bold/14-20"
          color="black100"
        >
          {formsOfWork}
        </ResponsiveTypography.Paragraph>
      </Item>
      <Item>
        <IconSpecificMoney fontSize={20} />
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="black100"
        >
          Lương:
        </ResponsiveTypography.Paragraph>
        <ResponsiveTypography.Paragraph
          variant="semi-bold/14-20"
          color="black100"
        >
          {salary}
        </ResponsiveTypography.Paragraph>
      </Item>
      <Item>
        <IconUsersGroup fontSize={20} />
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="black100"
        >
          Vị trí tuyển dụng:
        </ResponsiveTypography.Paragraph>
        <ResponsiveTypography.Paragraph
          variant="semi-bold/14-20"
          color="black100"
        >
          {amount}
        </ResponsiveTypography.Paragraph>
      </Item>
      <Item>
        <IconNavigationPin fontSize={20} />
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="black100"
        >
          Địa điểm làm việc:
        </ResponsiveTypography.Paragraph>
        <ResponsiveTypography.Paragraph
          variant="semi-bold/14-20"
          color="black100"
        >
          {address}
        </ResponsiveTypography.Paragraph>
      </Item>
    </Container>
  );
};
