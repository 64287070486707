import React from 'react';
import PropTypes from 'prop-types';

import OutlineInterfaceIcDCheck from '@dolstaff/shared/es/icons/OutlineInterfaceIcDCheck';
import Typo from '@dolstaff/shared/es/Typo';
import parse from 'html-react-parser';
import styled from 'styled-components';

import { normalizedText } from '../../utils/htmlcontent.util';
import { colorsV2 } from 'style/colors-v2';
import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';
import { ButtonStyles } from 'components/ButtonStyles/ButtonStyles';
import { fromScreen } from 'utils/media-query/responsive.util';
import cl from 'classnames';

import { CareerDescription } from '../LandingTeacher/CareerDescription';

const JobDescriptionWrapper = styled.div`
  max-width: 712px;
  margin: 0 auto;
`;

const Banner = styled.div`
  background-color: ${colorsV2.paper};
  padding: 32px 16px;
  .content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .action {
      width: fit-content;
      margin-top: 24px;
    }
  }
  ${fromScreen(776)} {
    padding: 60px 0;
    .content {
      max-width: 712px;
      text-align: center;
      align-items: center;

      .action {
        margin-top: 32px;
      }
    }
  }
`;

const Panel = styled.div`
  margin-top: 32px;
  @media screen and (min-width: 1024px) {
    display: flex;

    .panelLeft {
      flex: auto;
    }
    .panelRight {
      flex: 450px 0 0;
      margin-left: 6em;
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1em;

  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
`;

const Heading = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1em;

  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
`;

const SubSection = styled.div`
  margin-bottom: 2.5em;

  .content {
    font-size: 18px;
    line-height: 1.4;

    p {
      margin-bottom: 1em;
    }

    li {
      margin-bottom: 0.75em;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 5em;
  }
`;

const Bullet = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .bulletIcon {
    background-color: #00875a;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    color: white;
    text-align: center;
    padding: 4px;
    margin-right: 8px;

    svg {
      display: block;
    }
  }
  .bulletContent {
    font-size: 20px;
  }
`;

const Main = styled.div`
  padding: 32px 16px;
  ${fromScreen(776)} {
    padding: 32px;
  }
`;

const ApplyNow = styled.a`
  padding: 10px 16px;
`;

class JobDetail extends React.PureComponent {
  render() {
    const { job } = this.props;
    const {
      formsOfWork,
      salaryDisplay,
      amount,
      address,
      partTime,
      applicationProcess
    } = job;

    const showOrder = applicationProcess && applicationProcess.length > 1;

    return (
      <div>
        <Banner>
          <div className="content">
            <ResponsiveTypography.Paragraph
              color="black100"
              mb="16px"
              breakpoints={[0, 776]}
              variant={['bold/24-32', 'bold/40-48']}
            >
              {job.title}
            </ResponsiveTypography.Paragraph>
            <ResponsiveTypography.Paragraph
              color="black80"
              breakpoints={[0, 776]}
              variant={['regular/14-20', 'regular/20-28']}
            >
              DOL đang tìm kiếm ứng viên cho vị trí {job.title}. Ứng tuyển ngay
              để cùng gia nhập team DOL nhé!
            </ResponsiveTypography.Paragraph>
            <div className="action">
              <ButtonStyles
                buttonStyle="filled"
                content={
                  <ApplyNow href="#quy-trinh-ung-tuyen">
                    <ResponsiveTypography.Paragraph
                      variant="semi-bold/14-24"
                      color="white100"
                    >
                      Ứng tuyển ngay
                    </ResponsiveTypography.Paragraph>
                  </ApplyNow>
                }
                color="primary"
                colorStyle="bold"
              />
            </div>
          </div>
        </Banner>
        <Main>
          <JobDescriptionWrapper>
            <CareerDescription
              isPartTime={partTime}
              formsOfWork={formsOfWork}
              amount={amount}
              salary={salaryDisplay}
              address={address}
            />
            <Panel>
              <div className="panelLeft">
                <SubSection>
                  <Heading>3 lý do bạn sẽ yêu thích công việc này</Heading>
                  <Bullet>
                    <div className="bulletIcon">
                      <OutlineInterfaceIcDCheck size={16} />
                    </div>
                    <p className="bulletContent">{job.reasonToJoin1}</p>
                  </Bullet>
                  <Bullet>
                    <div className="bulletIcon">
                      <OutlineInterfaceIcDCheck size={16} />
                    </div>
                    <p className="bulletContent">{job.reasonToJoin2}</p>
                  </Bullet>
                  <Bullet>
                    <div className="bulletIcon">
                      <OutlineInterfaceIcDCheck size={16} />
                    </div>
                    <p className="bulletContent">{job.reasonToJoin3}</p>
                  </Bullet>
                </SubSection>
                <SubSection>
                  <Title>Mô tả công việc</Title>
                  <Typo
                    className="content"
                    htmlContent={job.jobDescription.html}
                  />
                </SubSection>
                <SubSection>
                  <Title>Yêu cầu</Title>
                  <Typo
                    className="content"
                    htmlContent={job.requirement.html}
                  />
                </SubSection>

                {job.benefit && job.benefit.html ? (
                  <SubSection>
                    <Title>Quyền lợi</Title>
                    <Typo className="content" htmlContent={job.benefit.html} />
                  </SubSection>
                ) : null}

                {job.applicationProcess && !!job.applicationProcess.length && (
                  <SubSection>
                    <Title id="quy-trinh-ung-tuyen">Quy trình ứng tuyển</Title>
                    <Requirements
                      className={cl({
                        showOrder
                      })}
                    >
                      {job.applicationProcess &&
                        job.applicationProcess.map(({ html }, idx) => (
                          <React.Fragment key={`${idx}`}>
                            {showOrder && <span>{idx + 1}</span>}
                            {parse(normalizedText(html))}
                          </React.Fragment>
                        ))}
                    </Requirements>
                  </SubSection>
                )}
              </div>
            </Panel>
          </JobDescriptionWrapper>
        </Main>
      </div>
    );
  }
}

JobDetail.propTypes = {
  job: PropTypes.shape({
    reasonToJoin1: PropTypes.string,
    reasonToJoin2: PropTypes.string,
    reasonToJoin3: PropTypes.string,
    team: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.string,
    salaryDisplay: PropTypes.string,
    aboutCompany: PropTypes.string,
    benefit: PropTypes.shape({
      html: PropTypes.string
    }),
    requirement: PropTypes.shape({
      html: PropTypes.string
    }),
    jobDescription: PropTypes.shape({
      html: PropTypes.string
    }),
    applicationProcess: PropTypes.arrayOf(
      PropTypes.shape({
        html: PropTypes.string
      })
    )
  })
};

export default JobDetail;

const Requirements = styled.div`
  &.showOrder {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 16px;
  }

  span {
    width: 24px;
    height: 24px;
    background-color: #00875a;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: none;
    font-weight: normal;
    font-size: 12px;
  }

  p {
    font-size: 18px;
  }
`;
