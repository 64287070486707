import * as React from 'react';

export const IconSpecificSuitcase = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 7.49898C1.875 6.23295 2.90133 5.2065 4.16736 5.2065H6.04167V4.16431C6.04167 2.89827 7.06799 1.87195 8.33403 1.87195H11.6688C12.9348 1.87195 13.9611 2.89827 13.9611 4.16431V5.2065H15.8389C17.1049 5.2065 18.1313 6.23283 18.1313 7.49886V14.1683C18.1313 16.3552 16.3584 18.128 14.1715 18.128H5.83472C3.64783 18.128 1.875 16.3552 1.875 14.1683V7.49898ZM8.33403 3.12195C7.75835 3.12195 7.29167 3.58863 7.29167 4.16431V5.2065H12.7111V4.16431C12.7111 3.58863 12.2444 3.12195 11.6688 3.12195H8.33403ZM6.66667 6.45667L6.65203 6.4565H4.16736C3.59168 6.4565 3.125 6.92318 3.125 7.49886C3.125 8.76519 4.15156 9.79186 5.41788 9.79186H7.70833V9.58293C7.70833 9.00763 8.1747 8.54126 8.75 8.54126H11.2514C11.8267 8.54126 12.2931 9.00763 12.2931 9.58293V9.79186H14.5845C15.8509 9.79186 16.8774 8.76531 16.8774 7.49898H16.8813C16.8813 6.9233 16.4146 6.4565 15.8389 6.4565H13.3508L13.3361 6.45667L13.3215 6.4565H6.68131L6.66667 6.45667ZM8.95833 10.4724C8.95994 10.4541 8.96076 10.4356 8.96076 10.4169C8.96076 10.3982 8.95994 10.3797 8.95833 10.3614V9.79126H11.0431V10.3749C11.0421 10.3887 11.0417 10.4027 11.0417 10.4169C11.0417 10.431 11.0421 10.445 11.0431 10.4589V11.0423H8.95833V10.4724ZM7.70833 11.0419H5.41788C4.54342 11.0419 3.74295 10.7251 3.125 10.1999V14.1683C3.125 15.6648 4.33818 16.878 5.83472 16.878H14.1715C15.6681 16.878 16.8813 15.6648 16.8813 14.1683V10.1967C16.2628 10.7237 15.4608 11.0419 14.5845 11.0419H12.2931V11.2506C12.2931 11.8259 11.8267 12.2923 11.2514 12.2923H8.75C8.1747 12.2923 7.70833 11.8259 7.70833 11.2506V11.0419Z"
        fill="currentColor"
      />
    </svg>
  );
};
