import * as React from 'react';

export const IconTimeTime2 = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76562 4.98833C10.1108 4.98833 10.3906 5.26815 10.3906 5.61333V10.5317L14.1875 12.7966C14.4839 12.9734 14.5809 13.3571 14.4041 13.6535C14.2272 13.95 13.8436 14.0469 13.5471 13.8701L9.44545 11.4234C9.25641 11.3107 9.14062 11.1068 9.14062 10.8867V5.61333C9.14062 5.26815 9.42045 4.98833 9.76562 4.98833Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8613 5.13864C12.1765 2.45379 7.8235 2.45379 5.13864 5.13864L5.13864 5.13864C2.45379 7.82348 2.45379 12.1765 5.13864 14.8613C7.82348 17.5462 12.1765 17.5462 14.8613 14.8613C17.5461 12.1766 17.5462 7.82381 14.8616 5.13893C14.8615 5.13883 14.8614 5.13874 14.8613 5.13864ZM15.7452 4.25476C18.9182 7.42777 18.9182 12.5722 15.7452 15.7452C12.5722 18.9182 7.42775 18.9182 4.25476 15.7452C1.08175 12.5722 1.08175 7.42775 4.25476 4.25476C7.42777 1.08175 12.5722 1.08175 15.7452 4.25476Z"
        fill="currentColor"
      />
    </svg>
  );
};
