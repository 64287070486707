import * as React from 'react';

export const IconNavigationPin = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78906 8.33333C4.78906 5.45684 7.12091 3.125 9.9974 3.125C12.8739 3.125 15.2057 5.45684 15.2057 8.33333C15.2057 10.282 13.8831 12.4233 12.4345 14.1565C11.725 15.0053 11.0137 15.7231 10.4792 16.229C10.2945 16.4038 10.1314 16.5529 9.9974 16.6728C9.86337 16.5529 9.70026 16.4038 9.51555 16.229C8.98105 15.7231 8.26979 15.0053 7.56028 14.1565C6.11167 12.4233 4.78906 10.282 4.78906 8.33333ZM9.9974 17.5C9.60068 17.983 9.60056 17.9828 9.60041 17.9827L9.9974 17.5ZM9.59877 17.9814L9.60041 17.9827C9.83097 18.1721 10.1635 18.1723 10.3941 17.983L9.99802 17.5008C10.3941 17.983 10.3946 17.9825 10.3948 17.9824L10.396 17.9814L10.4 17.9781L10.4143 17.9662C10.4265 17.956 10.4441 17.9413 10.4667 17.9222C10.5119 17.8839 10.5771 17.8281 10.6596 17.7559C10.8245 17.6117 11.0584 17.4019 11.3385 17.1369C11.8977 16.6076 12.6448 15.854 13.3936 14.9581C14.8617 13.2017 16.4557 10.7597 16.4557 8.33333C16.4557 4.76649 13.5642 1.875 9.9974 1.875C6.43055 1.875 3.53906 4.76649 3.53906 8.33333C3.53906 10.7597 5.13312 13.2017 6.60118 14.9581C7.35 15.854 8.09707 16.6076 8.65632 17.1369C8.93635 17.4019 9.1703 17.6117 9.33518 17.7559C9.41764 17.8281 9.48291 17.8839 9.52809 17.9222C9.55069 17.9413 9.56828 17.956 9.58049 17.9662L9.59475 17.9781L9.59877 17.9814ZM8.1224 8.33333C8.1224 7.29768 8.96174 6.45833 9.9974 6.45833C11.0331 6.45833 11.8724 7.29768 11.8724 8.33333C11.8724 9.36899 11.0331 10.2083 9.9974 10.2083C8.96174 10.2083 8.1224 9.36899 8.1224 8.33333ZM9.9974 5.20833C8.27138 5.20833 6.8724 6.60732 6.8724 8.33333C6.8724 10.0593 8.27138 11.4583 9.9974 11.4583C11.7234 11.4583 13.1224 10.0593 13.1224 8.33333C13.1224 6.60732 11.7234 5.20833 9.9974 5.20833Z"
        fill="currentColor"
      />
    </svg>
  );
};
