import * as React from 'react';

export const IconSpecificMoney = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79167 5.20833C10.1368 5.20833 10.4167 5.48816 10.4167 5.83333V6.875C10.4167 7.22018 10.1368 7.5 9.79167 7.5C9.44649 7.5 9.16667 7.22018 9.16667 6.875V5.83333C9.16667 5.48816 9.44649 5.20833 9.79167 5.20833Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79167 12.5C10.1368 12.5 10.4167 12.7798 10.4167 13.125V14.1667C10.4167 14.5118 10.1368 14.7917 9.79167 14.7917C9.44649 14.7917 9.16667 14.5118 9.16667 14.1667V13.125C9.16667 12.7798 9.44649 12.5 9.79167 12.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08333 8.3375C7.08333 7.18505 8.01709 6.25 9.17083 6.25H10.3125C11.4747 6.25 12.4122 7.15666 12.4878 8.2918C12.5107 8.63622 12.2501 8.93401 11.9057 8.95695C11.5613 8.97989 11.2635 8.71928 11.2405 8.37487C11.2078 7.88334 10.8036 7.5 10.3125 7.5H9.17083C8.70791 7.5 8.33333 7.87495 8.33333 8.3375C8.33333 8.7216 8.59495 9.05685 8.96713 9.15046L10.9196 9.64046C11.8487 9.87362 12.4992 10.7087 12.4992 11.6658C12.4992 12.8196 11.5641 13.7533 10.4117 13.7533H9.27C8.10536 13.7533 7.16821 12.8441 7.09383 11.7075C7.07129 11.363 7.33225 11.0655 7.67669 11.043C8.02113 11.0205 8.31863 11.2814 8.34117 11.6259C8.37345 12.1192 8.77798 12.5033 9.27 12.5033H10.4117C10.8742 12.5033 11.2492 12.1288 11.2492 11.6658C11.2492 11.2813 10.988 10.9464 10.6154 10.8529L8.66287 10.3629C7.73514 10.1297 7.08333 9.295 7.08333 8.3375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10Z"
        fill="currentColor"
      />
    </svg>
  );
};
